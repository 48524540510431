import "./index.css";

const BladeButton = ({
  text = "",
  color1 = "rgba(212, 192, 160, 1)",
  color2 = "rgba(231, 221, 204, 1)",
  onClick = () => {},
}) => {
  return (
    <div
      className="btnStyle p-2 text-center"
      style={{ background: `linear-gradient(${color1},${color2})` }}
      onClick={onClick}
    >
      <div className="mx-auto my-auto mt-2 text-uppercase btn-text">{text}</div>

      <div className="divStyle styleTopLeft"></div>
      <div className="divStyle styleTopRight"></div>
      <div className="divStyle styleBottomLeft"></div>
      <div className="divStyle styleBottomRight"></div>
    </div>
  );
};

export default BladeButton;
