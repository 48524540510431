import HeadingWithCloudEffect from "../../../common/headingWithCloudBg";
import CustomButton from "../../../common/customButton";
import TelegramImg from "../../../../assets/telegram.svg";
import Twitter from "../../../../assets/twitter.svg";
import Discord from "../../../../assets/discord.svg";
import "./index.css";

const MemesExp = () => {
  const onBtnClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="memesexp position-relative">
      <div className="col-11 col-xl-5 mx-auto">
        <HeadingWithCloudEffect
          heading={"be part of game of memes experience"}
          content={
            "Join our community on social media, participate in discussions, and stay updated with the latest developments."
          }
        />
      </div>
      <div className="mx-auto col-11 col-lg-8 d-flex justify-content-between flex-wrap">
        <CustomButton
          iconImg={TelegramImg}
          text={"join us on telegram"}
          width={"30%"}
          onBtnClick={() => onBtnClick("https://t.me/gameofmemescoin")}
        />
        <CustomButton
          iconImg={Twitter}
          text={"follow us on twitter"}
          width={"30%"}
          onBtnClick={() => onBtnClick("https://twitter.com/GOM_base")}
        />
        {/* <CustomButton
          iconImg={Discord}
          text={"stay updated on discord"}
          width={"30%"}
          onBtnClick={() => onBtnClick("https://www.google.com")}
        /> */}
      </div>
    </div>
  );
};

export default MemesExp;
