import "./index.css";
import gom_logo from "../../../assets/gom-transparent-logo.png";
import FooterItems from "../footerItems";
import TelegramImg from "../../../assets/telegram.svg";
import Twitter from "../../../assets/twitter.svg";
import Discord from "../../../assets/discord.svg";

const Footer = () => {
  return (
    <div className="d-flex flex-column footerStyles vh-60 vw-100 p-3 justify-content-center align-items-center">
      <div className="col-lg-9">
        <div className="d-flex flex-row p-3 m-3 row">
          <div className="col-12 col-lg-3 d-flex justify-content-center justify-content-lg-start mb-3 mb-lg-0">
            <img src={gom_logo} alt="" className="img-fluid" />
          </div>
          <div className="col-12 col-lg-6 mb-3 mb-lg-0">
            <FooterItems />
          </div>
          <div className="col-12 col-lg-3 d-flex flex-row justify-content-center justify-content-lg-end">
            <a href="https://t.me/gameofmemescoin" target="_blank" rel="noopener noreferrer">
              <img src={TelegramImg} alt="" className="mx-2 footer-icons" />
            </a>
            <a href="https://twitter.com/GOM_base" target="_blank" rel="noopener noreferrer">
             <img src={Twitter} alt="Twitter" className="mx-2 footer-icons" />
            </a>
            
          </div>
        </div>
      </div>

      <hr className="hrStyles" />

      <div className="p-3 m-3 ">
        <ul className="footerNavStyles d-flex flex-column flex-lg-row p-0">
          {/* <li className="footer-note-text p-0 mx-0 mx-lg-3">
            2023. All right reserved
          </li> */}
          {/* <li className="mx-0 mx-lg-3 underline">
            <a href="" className="footer-note-text">
              Privacy policy
            </a>
          </li>
          <li className="mx-0 mx-lg-3 underline">
            <a href="" className="footer-note-text">
              {" "}
              Terms of service
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default Footer;
