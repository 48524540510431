const FooterItems = () => {
  return (
    <div className="px-2 d-flex justify-content-center">
      <div className="listStyles d-block d-lg-flex flex-row">
        <div>
          <a href="#welcome" className="p-2 text-light text-decoration-none">
            ABOUT
          </a>
        </div>
        <div>
          <a href="#tokenomics" className="p-2 text-light text-decoration-none">
            TOKENOMICS
          </a>
        </div>
        <div>
          <a href="#how-to-buy" className="p-2 text-light text-decoration-none">
            HOW TO BUY
          </a>
        </div>
        <div>
          <a href="#roadmap" className="p-2 text-light text-decoration-none">
            ROADMAP
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterItems;
