import "./App.css";
import Button from "../src/components/common/button";
import Navbar from "../src/components/common/navbar";
import Welcome from "./components/screens/landingPage/welcome";
import InitialSection from "./components/screens/landingPage/initialSection";
import DragonSection from "./components/screens/landingPage/dragonSection";
import Footer from "./components/common/footer";
import HowToBuy from "./components/screens/landingPage/howToBuy";
import { LogoList } from "./components/screens/landingPage/logoList";
import Tokenomics from "./components/screens/landingPage/tokenomics";
import Roadmap from "./components/screens/landingPage/roadmap";
import MemesExp from "./components/screens/landingPage/memesExp";
import CustomButton from "./components/common/customButton";

function App() {
  return (
    <div className="App">
      <Navbar />
      <InitialSection />
      <LogoList />
      <Welcome />
      <Tokenomics />
      <DragonSection />
      <HowToBuy />
      <Roadmap />
      <MemesExp />
      <Footer />
    </div>
  );
}

export default App;
