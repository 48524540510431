// initial section component
import "./index.css";
import BladeButton from "../../../common/bladeButton";

const InitialSection = () => {
  const onBtnClick = (link = "https://twitter.com/GOM_base") => {
    window.open(link);
  };

  return (
    <div className="initial-section">
      <img
        src={require("../../../../assets/king.png")}
        className="img-fluid d-sm-none"
        style={{ marginTop: "60px" }}
      />
      <div className="col-11 col-lg-7 mx-auto">
        <img
          src={require("../../../../assets/title.png")}
          className="king-img"
          style={{ width: "100%" }}
        />
        <br />
        <img
          src={require("../../../../assets/gomCommonLogo.png")}
          className="img-fluid separator-img my-4"
        />
        <p className="section-content text-uppercase">
        Dive into a world where the epic saga of Game of Thrones meets the iconic internet and crypto legends on their quest to glory.
         {/* <span className="gom-text">$GOM!</span> */}
        </p>
        <div className="d-flex flex-column flex-md-row btn-groups w-75 justify-content-center mx-auto my-5">
          <BladeButton text="join presale" onClick={() => onBtnClick()} />
          <BladeButton
            text="buy token"
            color1="white"
            color2="white"
            onClick={() => onBtnClick()}
          />
        </div>
      </div>
    </div>
  );
};

export default InitialSection;
