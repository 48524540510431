import "./index.css";

const ImageWithStyledBorder = (props) => {
  return (
    <div className="borderStyles p-3 w-100 h-100">
      <img className="imgStyle" src={props.imgUrl} alt="" />

      <div className="squareStyles topLeft"></div>
      <div className="squareStyles bottomRight"></div>
    </div>
  );
};

export default ImageWithStyledBorder;
