import CustomButton from "../../../common/customButton";
import HeadingWithCloudEffect from "../../../common/headingWithCloudBg";
import "./index.css";

const Tokenomics = () => {
  const onBtnClick = (link) => {
    window.open(link, "_blank");
  };
  return (
    <div className="tokenomics" id="tokenomics">
      <div className="mx-auto col-11 col-lg-8">
        <HeadingWithCloudEffect
          heading={"tokenomics"}
          content={`There are no taxes on trading GOM, no hidden or malicious code, making trading or holding the precious GOM coins a pleasant experience.`}
        />
        <div className="d-flex flex-column flex-md-row justify-content-evenly p-4 p-md-5 gap-4 position-relative data-custom">
          <div className="line-1" />
          <div className="line-2" />
          <div className="line-3" />
          <div className="line-4" />
          <div className="position-absolute tokenomics-1">
            <img
              src={require("../../../../assets/tokenomics-1.png")}
              className="img-fluid"
            />
          </div>
          <div className="position-absolute tokenomics-2">
            <img
              src={require("../../../../assets/tokenomics-2.jpg")}
              className="img-fluid"
            />
          </div>
          <div className="position-absolute tokenomics-3">
            <img
              src={require("../../../../assets/tokenomics-1.png")}
              className="img-fluid"
            />
          </div>
          <div className="position-absolute tokenomics-4">
            <img
              src={require("../../../../assets/tokenomics-4.jpg")}
              className="img-fluid"
            />
          </div>
          <div className="col-8 m-auto col-md-2">
            <img
              className="img-fluid"
              style={{ width: "90%" }}
              src={require("../../../../assets/frog.png")}
            />
          </div>
          <div className="text-uppercase d-flex flex-column col-12 col-md-6 ">
            <div className="d-flex flex-row flex-wrap">
              <div className="text-start col-12 col-sm-6">
                <small>network</small>
                <p className="data">Base</p>
              </div>
              <div className="text-start">
                <small>token symbol</small>
                <p className="data">$gom</p>
              </div>
            </div>
            <hr />
            <div className="d-flex flex-row flex-wrap">
              <div className="text-start col-12 col-sm-6">
                <small>total supply</small>
                <p className="data text-wrap">100,000,000,000</p>
              </div>
              <div className="text-start col-12 col-sm-6">
                <small>liquidity pool</small>
                <p className="data">45%</p>
              </div>
            </div>
            <hr />
            <div className="d-flex flex-row flex-wrap">
              <div className="text-start col-12 col-sm-6">
                <small>Presale</small>
                <p className="data text-wrap">45%</p>
              </div>
              <div className="text-start col-12 col-sm-6">
                <small>Marketing</small>
                <p className="data">10%</p>
              </div>
            </div>
          </div>
          <div className="tax-det col-12 col-md-3">
            <div className="d-flex text-uppercase position-relative">
              <div className="text-start me-3">
                <small>buy tax</small>
                <p className="tax-per">0%</p>
              </div>
              <div
                style={{
                  width: "0.5px",
                  height: "60px",
                  backgroundColor: "rgba(41, 41, 41, 1)",
                  alignSelf: "center",
                }}
                className="me-3"
              ></div>
              <div className="text-start">
                <small>sell tax</small>
                <p className="tax-per">0%</p>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      <h4 className="text-uppercase proofs text-center mt-5">Token Links</h4>
      <div className="d-flex justify-content-center justify-content-lg-between col-11 col-lg-8 mx-auto my-5 flex-wrap">
        <CustomButton
          text={"contract"}
          width={"20%"}
          onBtnClick={() => onBtnClick("")}
        />
        <CustomButton
          text={"uniswap pool"}
          width={"20%"}
          onBtnClick={() => onBtnClick("")}
        />

        <CustomButton
          text={"locked liquidity"}
          width={"20%"}
          onBtnClick={() => onBtnClick("")}
        />
        <CustomButton
          text={"presale"}
          width={"20%"}
          onBtnClick={() => onBtnClick("https://twitter.com/GOM_base")}
        />
      </div>
    </div>
  );
};

export default Tokenomics;
