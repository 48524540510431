import ImageWithStyledBorder from "../../../common/imageWithStyledBorder";
import HeadingWithCloudEffect from "../../../common/headingWithCloudBg";
import accordianImg1 from "../../../../assets/1.png";
import accordianImg2 from "../../../../assets/accordian_img2.jpg";
import accordianImg3 from "../../../../assets/accordian_img3.jpg";
import accordianImg4 from "../../../../assets/accordian_img4.jpg";
import accordianImg5 from "../../../../assets/accordian_img5.jpg";
import accordianImg6 from "../../../../assets/accordian_img6.jpg";
import "./index.css";

import "./index.css";

const HowToBuy = () => {
  return (
    <div
      className="col-11 col-lg-8 mx-auto how-to-buy position-relative"
      id="how-to-buy"
    >
      <HeadingWithCloudEffect
        heading={"How to Buy"}
        content={
          "Follow these steps or join our Telegram group if you need help"
        }
      />
      <div className="accordion" id="accordionExample">
        <div className="accordion-item acItemStyle text-light custom-border active">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button btn-style "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              STEP 1
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show bg-dark custom-border"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body d-flex flex-column flex-md-row justify-content-evenly py-5 custom-border">
              <div className="step-img">
                <ImageWithStyledBorder imgUrl={accordianImg1} />
              </div>
              <div className="d-flex flex-column justify-content-evenly align-items-start p-2 col-12 col-md-6">
                <p className="step">STEP 1</p>
                <h2 className="text-start">NAVIGATE TO UNISWAP</h2>
                <p className="text-start">
                  Open your preferred browser and go to the uniswap website by
                  typing{" "}
                  <a
                    className="text-white text-decoration-none"
                    href="https://app.uniswap.org"
                  >
                    https://app.uniswap.org
                  </a>{" "}
                  into the address bar and pressing enter
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item custom-border text-light">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed btn-style"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              STEP 2
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse bg-dark text-light"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body d-flex flex-column flex-md-row justify-content-evenly py-5">
              <div className="step-img">
                <ImageWithStyledBorder imgUrl={accordianImg2} />
              </div>
              <div className="d-flex flex-column justify-content-evenly align-items-start p-2 col-12 col-md-6">
                <p className="step">STEP 2</p>
                <h2 className="text-start">SELECT "CONNECT WALLET"</h2>
                <p className="text-start">
                  On the uniswap homepage, you will see a "connect wallet"
                  button in the top right corner. Click on it to start the
                  process of connecting your evm wallet to uniswap
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item custom-border text-light">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed btn-style"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              STEP 3
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse bg-dark text-light"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body d-flex flex-column flex-md-row justify-content-evenly py-5">
              <div className="step-img">
                <ImageWithStyledBorder imgUrl={accordianImg3} />
              </div>
              <div className="d-flex flex-column justify-content-evenly align-items-start p-2 col-12 col-md-6">
                <p className="step">STEP 3</p>
                <h2 className="text-start">SELECT WALLET</h2>
                <p className="text-start">
                  Choose your preferred wallet provider from the list of
                  available options, such as MetalMask, WalletConnect or
                  Coinbase Wallet. Follow the prompts to connect your wallet to
                  uniswap. Depending upon your wallet provider, you may need to
                  approve the connection request and sign in to your wallet
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item custom-border text-light">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed btn-style"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              STEP 4
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse bg-dark text-light"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body d-flex flex-column flex-md-row justify-content-evenly py-5">
              <div className="step-img">
                <ImageWithStyledBorder imgUrl={accordianImg4} />
              </div>
              <div className="d-flex flex-column justify-content-evenly align-items-start p-2 col-12 col-md-6">
                <p className="step">STEP 4</p>
                <h2 className="text-start">SELECT TOKEN</h2>
                <p className="text-start">
                  Once your wallet is connected, copy and paste the official
                  contract for $GOM in the "to" field. Select the token from the
                  search results to continue
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item custom-border text-light">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed btn-style"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              STEP 5
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse bg-dark text-light"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body d-flex flex-column flex-md-row justify-content-evenly py-5 custom-border">
              <div className="step-img">
                <ImageWithStyledBorder imgUrl={accordianImg5} />
              </div>
              <div className="d-flex flex-column justify-content-evenly align-items-start p-2 col-12 col-md-6">
                <p className="step">STEP 5</p>
                <h2 className="text-start">ENTER AMOUNT</h2>
                <p className="text-start">
                  Once you have selected the token, you will see a form where
                  you can enter the amount of the token you want to buy. Fill in
                  the amount you want to buy, and the price will automatically
                  be calculated based on the current market rate
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-item custom-border text-light">
          <h2 className="accordion-header" id="headingSix">
            <button
              className="accordion-button collapsed btn-style"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              STEP 6
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse bg-dark text-light"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body d-flex flex-column flex-md-row justify-content-evenly py-5">
              <div className="step-img">
                <ImageWithStyledBorder imgUrl={accordianImg6} />
              </div>
              <div className="d-flex flex-column justify-content-evenly align-items-start p-2 col-12 col-md-6">
                <p className="step">STEP 6</p>
                <h2 className="text-start">REVIEW AND CONFIRM</h2>
                <p className="text-start">
                  If everything looks correct, approve the transaction in your
                  wallet by following the prompts. Confirm the transaction to
                  initiate the purchase and receive $GOM in your wallet
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src={require("../../../../assets/end.png")}
          className="my-5"
          style={{ maxWidth: "90%", height: "auto" }}
        />
      </div>
    </div>
  );
};

export default HowToBuy;
