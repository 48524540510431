import "./index.css";

const CustomButton = ({
  text,
  iconImg,
  width = "100%",
  onBtnClick = () => {},
}) => {
  return (
    <div
      className="border-styles"
      style={{ width: width }}
      onClick={() => onBtnClick()}
    >
      {iconImg && <img src={iconImg} />}
      <span className="text-uppercase text-style">{text}</span>
      <img src={require("../../../assets/outward-arrow.png")} />
      <div className="circle-styles topLeft"></div>
      <div className="circle-styles bottomRight"></div>
    </div>
  );
};

export default CustomButton;
