import "./index.css";
import logo1 from "../../../../assets/logo1.png";
import logo2 from "../../../../assets/logo2.png";
import logo3 from "../../../../assets/logo3.png";
import logo4 from "../../../../assets/logo4.svg";

export const LogoList = () => {
  return (
    <div className="companies-logo-wrapper d-flex flex-row flex-wrap justify-content-evenly p-4 col-11 col-lg-8 customStyle mx-auto">
      <a href="">
        <div className="p-2 img-wrapper">
          <img src={logo1} alt="" />
        </div>
      </a>
      <a href=" ">
        <div className="p-2 img-wrapper">
          <img src={logo2} alt="" />
        </div>
      </a>
      <a href=" ">
        <div className="p-2 img-wrapper">
          <img src={logo3} alt="" />
        </div>
      </a>
      <a href=" ">
        <div className="p-2 img-wrapper">
          <img src={logo4} alt="" />
        </div>
      </a>
    </div>
  );
};
