import "./index.css";
import gomCommonLogo from "../../../assets/gomCommonLogo.png";

const HeadingWithCloudEffect = (props) => {
  return (
    <div className="styleDiv d-flex flex-column justify-content-evenly text-light align-items-center w-100 p-2 my-2">
      <div className="p-2">
        <h1 className="text-uppercase mt-3 heading-wc">{props.heading}</h1>
      </div>

      <div className="p-2">
        <img src={gomCommonLogo} alt="" />
      </div>

      <div className="p-2">
        <p>{props.content}</p>
      </div>
    </div>
  );
};

export default HeadingWithCloudEffect;
