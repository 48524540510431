import "./index.css";
import layer1 from "../../../../assets/layer-1.png";
import layer2 from "../../../../assets/layer-2.png";
import layer3 from "../../../../assets/layer-3.png";
import layer4 from "../../../../assets/layer-4.png";
import separator from "../../../../assets/separator.png";

const Welcome = () => {
  return (
    <div className="welcome col-11 col-lg-8 mx-auto" id="welcome">
      <div className="welcome-inner-content mx-auto col-12 col-md-10  position-relative">
        <img src={layer1} className="position-absolute layer layer-1" />
        <img src={layer2} className="position-absolute layer layer-2" />
        <img src={layer3} className="position-absolute layer layer-3" />
        <img src={layer4} className="position-absolute layer layer-4" />
        <h3
          className="welcome-heading thin-text text-uppercase text-white mb-0"
          style={{ fontWeight: 300 }}
        >
          Welcome to
        </h3>
        <h3 className="welcome-heading text-uppercase text-white">
          the game of memes
        </h3>
        <img src={separator} className="separator" />
        <p className="item-content">
        Game of Memes is a celebration of creativity, humor, and community.
        Here, we unite the thrill of kingdoms in turmoil with the whimsical charm of meme culture.
        </p>
      </div>
    </div>
  );
};

export default Welcome;
