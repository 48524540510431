import "./index.css";

const ListDiv = (props) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center listItemStyle p-2 my-2">
      <div className="p-2">
        <div className="text-light text-start">{props.text}</div>
      </div>

      <div className="p-2">
        <img src={props.imgUrl} alt="" />
      </div>
    </div>
  );
};

export default ListDiv;
