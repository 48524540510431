import "./index.css";
import { useState, useEffect } from "react";

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      window.addEventListener("resize", getWidth);
    }
    return () => window.removeEventListener("resize", getWidth);
  }, []);

  const getWidth = () => {
    setWidth(window.innerWidth);
  };

  const leftList = [
    { name: "about", url: "#welcome" },
    { name: "tokenomics", url: "#tokenomics" },
    { name: "how to buy", url: "#how-to-buy" },
    { name: "roadmap", url: "#roadmap" },
  ];
  const rightIcons = [
    // { name: "discord.svg", url: "https://www.google.com" },
    { name: "telegram.svg", url: "https://t.me/gameofmemescoin" },
    { name: "twitter.svg", url: "https://twitter.com/GOM_base" },
  ];

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <nav class="navbar navbar-custom navbar-expand-lg">
      <div class="container-fluid custom-margin-horizontal">
        {width >= 992 && (
          <ul class="navbar-nav d-none d-lg-flex">
            {leftList.map((listItem) => {
              return (
                <li class="nav-item nav-list-item">
                  <a class="nav-link text-uppercase" href={listItem.url}>
                    {listItem.name}
                  </a>
                </li>
              );
            })}
          </ul>
        )}

        <button
          class="custom-toggler navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-list"
          aria-controls="navbar-list"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand">
          <img
            src={require("../../../assets/gom-transparent-logo.png")}
            className="img-fluid mx-auto"
          ></img>
        </a>
        {width < 992 && (
          <div
            class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse `}
            id="navbar-list"
          >
            <ul class="navbar-nav me-auto">
              {leftList.map((listItem) => {
                return (
                  <li
                    class="nav-item nav-list-item"
                    onClick={handleNavCollapse}
                  >
                    <a class="nav-link text-uppercase" href={listItem.url}>
                      {listItem.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        <ul class="d-lg-flex  d-none navbar-nav justify-content-end">
          {rightIcons.map((icon) => {
            return (
              <li class="nav-item">
                <a class="nav-link " href={icon.url}>
                  <img src={require(`../../../assets/${icon.name}`)} />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
