import tickMark from "../../../../assets/tick.png";
import emptyImg from "../../../../assets/empty.png";
import ListDiv from "../listDiv";
import genesisGrowth from "../../../../assets/genesisGrowth.png";
const List = () => {
  const leftArray = [
    {
      text: "Token presale",
      image: "tick",
    },
    { text: "Token audit", image: "empty" },
    { text: "Launch on Uniswap + LP lock", image: "empty" },
    { text: "Whitepaper release", image: "empty" },
    { text: "CG & CMC listing", image: "empty" },
    { text: "Major meme competitions", image: "empty" },
  ];

  const rightArray = [
    { text: "KOL onboarding", image: "empty" },
    { text: "First CEX listings", image: "empty" },
    { text: "Strategic partnerships", image: "empty" },
    { text: "PR campaigns", image: "empty" },
    { text: "Team expansion", image: "empty" },
    { text: "The games begin...", image: "empty" },
  ];

  return (
    <div className="p-2 position-relative">
      <div className="p-2 m-auto">
        <img
          src={genesisGrowth}
          className="mt-5 img-fluid d-none d-sm-block mx-auto"
          alt=""
        />
        <div className="d-flex align-items-center col-10 mx-auto gap-2 text-uppercase d-sm-none">
          <div className="position-relative">
            <img
              src={require("../../../../assets/diamond-filled.png")}
              style={{ width: "28px", height: "28px" }}
            />
            <p
              style={{
                color: "rgba(209, 189, 157, 1)",
                fontSize: "16px",
                position: "absolute",
                fontWeight: 500,
                left: -15,
                top: 30,
              }}
            >
              genesis
            </p>
          </div>
          <hr
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "rgba(75, 75, 75, 1)",
            }}
          />
          <div className="position-relative">
            <img
              src={require("../../../../assets/diamond-empty.png")}
              style={{ width: "28px", height: "28px" }}
            />
            <p
              style={{
                color: "rgba(209, 189, 157, 1)",
                fontSize: "16px",
                position: "absolute",
                fontWeight: 500,
                left: -20,
                top: 30,
              }}
            >
              growth
            </p>
          </div>
        </div>
      </div>

      <div className="p-2 d-flex flex-column flex-md-row justify-content-around align-items-start mt-5">
        <div className="d-flex flex-column justify-content-between p-0 p-sm-3">
          {leftArray.map((obj) => {
            if (obj.image == "empty")
              return <ListDiv text={obj.text} imgUrl={emptyImg} />;
            else return <ListDiv text={obj.text} imgUrl={tickMark} />;
          })}
        </div>

        <div className="d-flex flex-column justify-content-between p-0 p-sm-3">
          {rightArray.map((obj) => {
            if (obj.image == "empty")
              return <ListDiv text={obj.text} imgUrl={emptyImg} />;
            else return <ListDiv text={obj.text} imgUrl={tickMark} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default List;
