import "./index.css";
import Arrest from "../../../../assets/arrest.png";
import List from "../list";

const Roadmap = () => {
  return (
    <div className="road-map position-relative" id="roadmap">
      <div className="img-container mx-auto col-11 col-lg-8">
        <h1 className="text-uppercase road-map-text">roadmap</h1>
        <img
          src={require("../../../../assets/cloud.png")}
          className="position-absolute road-cloud"
        />
        <List />
        <div class="content">
          <img src={Arrest} alt="Placeholder Image" className="img-arrest" />
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
