import "./index.css";

const DragonSection = () => {
  return (
    <div className="position-relative dragon-section">
      <div className="col-11 col-lg-8 m-auto content" style={{ zIndex: 3 }}>
        <img src={require("../../../../assets/cloud.png")} className="cloud" />
        <img
          src={require("../../../../assets/dragon.png")}
          className="position-relative dragon-img"
        />
      </div>
    </div>
  );
};

export default DragonSection;
